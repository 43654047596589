import React from 'react';
import OptimizeIcon from 'svgs/icons/ic-search-status.svg';
import SecurityIcon from 'svgs/icons/ic-shield-tick.svg';
import CustomerIcon from 'svgs/icons/ic-user-search.svg';
import BankingIcon from 'svgs/icons/ic-wallet.svg';

export const FINTECH_BENEFITS = [
    {
        icon: <CustomerIcon width={42} height={42} />,
        text: 'fintech-page.customers',
        description: 'fintech-page.customersText',
    },
    {
        icon: <BankingIcon width={42} height={42} />,
        text: 'fintech-page.banking',
        description: 'fintech-page.bankingText',
    },
    {
        icon: <SecurityIcon width={42} height={42} />,
        text: 'fintech-page.data',
        description: 'fintech-page.dataText',
    },
    {
        icon: <OptimizeIcon width={42} height={42} />,
        text: 'fintech-page.optimize',
        description: 'fintech-page.optimizeText',
    },
];
