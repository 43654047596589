import React from 'react';
import { useIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import { AccordionContentSection } from 'modules/accordion-content-section';

import { BANKING_SOLUTIONS } from '../constants';

export const FintechBankingSection = () => {
    const { formatMessage } = useIntl();
    const image = useStaticQuery(graphql`
        {
            mobile: file(relativePath: { eq: "fintech/fintech-mobile.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        height: 460
                        formats: [PNG]
                    )
                }
            }
        }
    `);

    return (
        <AccordionContentSection
            titleId="fintech-page.fintechBanking"
            listData={BANKING_SOLUTIONS}
            img={image.mobile}
            alt={formatMessage({ id: 'fintech-page.fintechBankingAlt' })}
        />
    );
};
