import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import { ContentRows } from 'modules/content-rows';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const FintechRowsSection = () => {
    const { formatMessage } = useIntl();

    const data = useStaticQuery(graphql`
    {
        desktopImage: file(relativePath: { eq: "fintech/fintech-desktop.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    width: 680
                    formats: [PNG]
                )
            }
        }
        mobileRow: file(relativePath: { eq: "fintech/workshop-banner.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    width: 460
                    formats: [PNG]
                )
            }
        }
    }
`);
    return (
        <ContentRows
            columnsData={[
                {
                    title: 'fintech-page.weProvideTailored',
                    descriptions: [
                        formatMessage({
                            id: 'fintech-page.ourScalablePlatforms.0',
                        }),
                        formatMessage(
                            {
                                id: 'fintech-page.ourScalablePlatforms.1',
                            },
                            {
                                link: (chunks) => {
                                    return (
                                        <SPrefixLangLink
                                            to={
                                                PATHS.SOFTWARE_DEVELOPMENT_PROCESS
                                            }
                                        >
                                            {chunks[0]}
                                        </SPrefixLangLink>
                                    );
                                },
                            },
                        ),
                    ],
                    img: data.desktopImage,
                    isReversed: true,
                    alt: formatMessage({
                        id: 'fintech-page.weProvideTailoredAlt',
                    }),
                },
                {
                    title: 'fintech-page.fintechConsultationStrategy',
                    descriptions: [
                        formatMessage(
                            {
                                id: 'fintech-page.ourScalablePlatforms.2',
                            }
                        ),
                        formatMessage(
                            {
                                id: 'fintech-page.ourScalablePlatforms.3',
                            },
                        ),
                    ],
                    img: data.mobileRow,
                    isReversed: false,
                    alt: formatMessage({
                        id: 'fintech-page.fintechConsultationStrategyAlt',
                    }),
                },
            ]}
        />
    );
};
