import React from 'react';
import { SectionTilesSection } from 'modules/section-tiles-section';

import { FINTECH_BENEFITS } from './constants';

export const FintechDigitalSection = () => {
    return (
        <SectionTilesSection
            title="fintech-page.whyBecomeDigital"
            paragraph="fintech-page.goingDigital"
            data={FINTECH_BENEFITS}
        />
    );
};
