import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

export const useFintechData = () => {
    const images = useStaticQuery(graphql`
        {
            nomisSolutions: file(
                relativePath: { eq: "case-studies/nomis-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            moneyTransfer: file(
                relativePath: {
                    eq: "case-studies/money-transfer-application-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            cryptocurrencyExchangePlatform: file(
                relativePath: {
                    eq: "case-studies/cryptocurrency-exchange-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'case-studies-page.nomisSolutions',
            field: 'case-studies-page.field.fintech',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.nomisSolutions.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.BANKING_SYSTEM_SOFTWARE}`,
        },
        {
            title: 'case-studies-page.moneyTransferApplication',
            field: 'case-studies-page.field.fintech',
            tags: [CASE_STUDIES_TAG.MOBILE_DEVELOPMENT],
            image: images.moneyTransfer.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.MONEY_TRANSFER_APPLICATION}`,
        },
        {
            title: 'case-studies-page.cryptocurrencyExchangePlatform',
            field: 'case-studies-page.field.fintech',
            tags: [CASE_STUDIES_TAG.WEB_DEVELOPMENT],
            image: images.cryptocurrencyExchangePlatform.childImageSharp
                .gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CRYPTOCURRENCY_EXCHANGE_SOFTWARE}`,
        },
    ];
};
